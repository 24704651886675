let images = document.querySelectorAll("[data-modal]");
if (images.length > 0) {
    let modal = document.getElementById("modal");
    for (let i = 0; i < images.length; i++) {
        let image = images[i];
        image.addEventListener("click", function () {
            modal.classList.toggle("hidden");
            modal.childNodes[3].src = image.src;
        });
        modal.addEventListener("click", function () {
            modal.classList.toggle("hidden", true);
        })
    }
}
